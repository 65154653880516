@import "assets/roboto/style.css";

body {
  margin: 0;
}

body * {
    font-family: "Roboto", sans-serif;
}

.App {
  height: 100vh;
}

.BoardsList h3 {
  font-size: 18px;
  font-weight: 600;
}

.sidebar .Boards {
  height: 85vh;
  overflow-y: auto;
}

.Board:first-child {
  margin-top: 0 !important;
}

.Group h4 {
  font-size: 18px;
  font-weight: 300;
}

.ItemWrapper {
  align-items: center;
  border-bottom: 1px solid #FFF;
  display: flex;
}

.Item {
  align-items: center;
  background: rgb(245, 246, 248);
  border-bottom: 1px solid rgb(230, 233, 239);
  cursor: pointer;
  display: flex;
  height: 36px;
  line-height: 36px;
  width: 100%;
}

.ItemUpdates {
  background: transparent;
  border: 0;
  color: #e6e9ef;
  margin-left: auto;
}

.ItemUpdates:hover {
  color: #0071d9;
}

.ItemUpdates svg {
  height: 36px;
  width: 24px;
}

.ItemColumn {
  background: rgb(245, 246, 248);
  border-bottom: 1px solid rgb(230, 233, 239);
  border-left: 1px solid rgb(230, 233, 239);
  color: rgb(50, 51, 56);
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.Item .ItemColor {
  flex: 0 0 8px;
  height: 100%;
}

.Item::before {
  background: #000;
  height: 100%;
  width: 10px;
}

.Item h5 {
  color: rgb(50, 51, 56);
  font-size: 13px;
  font-weight: 400;
  line-height: inherit;
  height: inherit;
  margin: 0 0 0 15px;
  overflow: hidden;
  text-overflow: "...";
  width: 90%;
  white-space: nowrap;
}

.TemporitaCalendar {
  height: 90vh;
  position: relative;
}

.Preloader {
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: calc(100% - 16px);
  z-index: 999;
}

.Preloader .spinner {
  height: 64px;
  position: absolute;
  width: 64px;
}

.Preloader .spinner svg {
  color: #0085FF;
}

.TemporitaCalendarEvent {
}

.TemporitaCalendarEvent h4 {
  align-items: center;
  justify-content: center;
  color: rgb(50, 51, 56);
  display: flex;
  font-size: 18px;
  left: 50%;
  height: 100%;
  margin-left: -35%;
  padding-top: 8px;
  position: absolute;
  width: 70%;
}

.TemporitaCalendarEvent.lessThan20Minutes h4 {
  font-size: 12px;
}

.TemporitaCalendarEvent.lessThan30Minutes h4 {
  font-size: 16px;
}

.TemporitaCalendarEvent .EventColor {
  background: #000;
  height: 8px;
  position: absolute;
  width: 100%;
  top: 0;
}

.TemporitaCalendarEventWeek .EventColor {
  background: #000;
  height: 100%;
  left: 0;
  position: absolute;
  width: 8px;
}

.TemporitaCalendarEvent .EventDuration {
  align-items: center;
  color: #0085FF;
  display: flex;
  height: 100%;
  padding: 8px 0 0 8px;
  position: absolute;
  top: 0;
  width: 100%;
}

.TemporitaCalendarEvent .EventLabel {
  align-items: center;
  display: flex;
  padding-right: 8px;
}

.TemporitaCalendarEventWeek h5 {
  color: #0085FF;
  margin-left: 16px;
}

.TemporitaCalendarEventWeek h4 {
  color: #000;
  font-size: 14px;
  margin-left: 16px;
  padding-right: 8px;
}

.TemporitaCalendarEvent.lessThan30Minutes .EventDuration {
  text-align: unset;
}

.TemporitaCalendarEvent .EventDuration h5 {
  align-items: center;
  display: flex;
  font-size: 18px;
  margin: 0 8px 0 auto;
}

.TemporitaCalendarEvent .EventDuration h5 button {
  color: #0085FF;
  margin: 0 0 0 8px;
}

.TemporitaCalendarEvent .EventLabel button {
  color: #0085FF;
  margin: 0 8px 0 0;
}

.TemporitaCalendarEvent.lessThan20Minutes .EventDuration h5 {
  font-size: 12px;
}

.TemporitaCalendarEventWeek.lessThan20Minutes h5,
.TemporitaCalendarEventWeek.lessThan20Minutes span.EventLabel {
  display: none;
}

.TemporitaCalendarEvent.lessThan20Minutes .EventDuration h5 {
  font-size: 12px;
}

.TemporitaCalendarEvent.lessThan20Minutes .EventDuration h5 button {
  font-size: 12px;
  min-height: 12px;
  padding: 0;
  line-height: 12px;
}

.TemporitaCalendarEvent.lessThan20Minutes .EventDuration h5 button svg {
  height: 14px;
}

.TemporitaCalendarEvent.lessThan30Minutes .EventDuration h5 {
  font-size: 14px;
}

.TemporitaCalendarEvent.lessThan20Minutes .EventLabel {
  font-size: 12px;
}

.TemporitaCalendarEvent.lessThan30Minutes h4 {
  font-size: 16px;
}

.TemporitaCalendarEvent.lessThan30Minutes h4 {
  font-size: 16px;
}

.rbc-event {
  background: #F5F6F8;
  box-shadow: 0.0px 4.0px 8.0px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #EEE !important;
  border-radius: 8px;
  padding: 0;
}

.rbc-event.rbc-selected {
  background: #F5F6F8;
}

.rbc-addons-dnd-resize-ns-icon {
  color: #000;
  width: 30px !important;
  z-index: 2;
  position: relative;
}

.rbc-event-label {
  display: none;
}

.rbc-today {
  background: #FFF;
}

.rbc-toolbar button {
  border: none;
  margin: 0 8px 0 0;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: none;
}

.rbc-header {
  font-weight: normal;
}

.ModalHeader {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.ModalBody {
  height: 50vh;
  overflow: auto;
  padding: 0 16px;
  position: relative;
}

.ModalFooter {
  align-content: center;
  display: flex;
  justify-content: center;
}

.ModalFooter button {
  height: 80%;
  margin: 8px;
}

.PlanningProgressBar {
  align-items: center;
  display: flex;
  margin: 10px auto 0;
  width: 50%;
}

.PlanningProgressBar .TemporitaProgressBar {
  margin-right: 8px;
  width: 80%;
}

.TodayIntentions {
  padding: 32px 0;
}

.TemporitaToday {
  text-align: center;
}

.TemporitaToday a {
  color: #323338;
  text-decoration: underline;
}

.TemporitaToday a:hover {
 text-decoration: none;
}

.TemporitaToday h2, .TemporitaToday h3, .TemporitaToday h4,
.Reports h2, .Reports h3, .Reports h4,
.WelcomeScreen h2, .WelcomeScreen h3, .WelcomeScreen h4 {
  text-align: center;
  width: 100%;
}

.TemporitaToday h2, .Reports h2, .WelcomeScreen h2 {
  color: #323338;
  font-size: 32px;
  font-weight: 500;
}

.TemporitaToday h3, .Reports h3, .WelcomeScreen h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 32px;
}

.TemporitaToday h4, .Reports h4, .WelcomeScreen h4 {
  color: #676879;
  font-size: 18px;
  font-weight: 500;
}

.TemporitaToday .TodayCurrentTime {
  color: #0085FF;
}

.TemporitaToday .ItemWrapper {
  margin: 16px auto 0;
  text-align: left;
  width: 60%;
}

.TemporitaToday .TodayItemTime {
  font-size: 18px;
  font-weight: 600;
}

.AddNewImplementationIntention label {
  font-size: 14px;
  line-height: 22px;
  margin: 0 8px 0 0;
}

.ButtonExplanation {
  margin: 0 0 0 8px;
}

.ModalExplanation {
  justify-content: center;
  display: flex;
  font-size: 16px;
  flex-direction: column;
}

.AddNewImplementationIntention {
  margin: 32px 0 0 0;
}

.monday-style-button.monday-style-button--loading {
  width: 40px !important;
}

.AddNewImplementationIntention .monday-style-button {
  width: 100%;
}

.monday-style-button--size-sm .monday-style-button__loader {
  height: 20px !important;
  width: 25px !important;
}

.monday-style-button--size-md .monday-style-button__loader {
  height: 25px !important;
  width: 25px !important;
}

.AddNewImplementationIntention .form-group {
  align-items: center;
  display: flex;
  justify-content: center;
}

.SelectorContainer {
  font-size: 14px;
}

.SelectorContainer .jss10 {
  height: 32px;
  width: 32px;
}

.SelectorContainer .jss19 {
  font-size: 18px;
}

.MenuButtons {
  margin: 8px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
}

#Logo {
  height: 30px;
  left: 16px;
  position: absolute;
}

.MenuButtons .monday-style-button {
  margin: 0 8px 0 0;
}

.SelectUserPhoto {
  border-radius: 16px;
  height: 32px;
  margin: 0 8px 0 0;
}

.SelectorUsers {
  margin: 16px auto;
  text-align: left;
  width: 40%;
}

.ReportsWeekNavigator {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 64px auto 0;
  width: 50%;
}

.ReportsWeekNavigator button {
  margin: 0 16px;
}

.ReportsProgressBar {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.ReportsProgressBar .TemporitaProgressBar {
  margin: 0 16px 0 64px;
  width: 30%;
}

.ReportsItems {
  margin: 32px auto;
  width: 40%;
}

.ReportsItems h4 {
  margin: 16px 0 8px;
  text-align: left;
}

.ReportsNoItems {
  text-align: center;
}

.ReportsDownloadButton {
  margin: 32px 0 0 0;
  text-align: center;
}

.SearchFilter {
  align-items: center;
  display: flex;
}

.SearchFilter .input-component {
  width: 90%;
}

.SearchFilter button {
  margin: 0 0 0 8px;
}

.FilterMenu {
  background: #FFF;
  border-radius: 16px;
  box-shadow: 0.0px 4.0px 8.0px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
}

.WelcomeScreen {
  padding: 64px 0;
  text-align: center;
}

.WelcomeScreenCheckItems {
  margin: 32px auto;
  text-align: left;
  width: 40%;
}

.WelcomeScreenCheckItems h4 {
  margin: 16px 0;
}

.WelcomeScreenCheckItems input {
  height: 16px;
  margin: 0 8px;
  vertical-align: middle;
  width: 16px;
}

.WelcomeScreenCTA {
  margin: 16px 0 0 0;
}

.GoToPlanner {
  background: none;
  border: none;
  margin: 32px 0;
}

.GoToPlanner .monday-style-attention-box-component--type-primary__text {
  text-decoration: underline;
}
